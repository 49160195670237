
import portfolioLinkedin from "../media/portfolio-linkedin.svg";
import portfolioGithub from "../media/portfolio-github.svg";

function Footer() {
    return (
        <div className=" text-white py-4 text-center">
            <div className="flex justify-center space-x-4">
                <a href="https://www.linkedin.com/in/genellepalana"><img src={portfolioLinkedin} alt="LinkedIn" className="h-6 w-6 filter invert" /></a>
                <a href="https://github.com/genellepalana"><img src={portfolioGithub} alt="GitHub" className="h-6 w-6 filter invert" /></a>
            </div>
            <p className="mt-2">© 2024 Genelle Palana</p>
        </div>
    );
}
  
export default Footer;