import { Link } from "react-router-dom";
import logo from "../media/portfolio-logo.svg";
import React, { useState, useEffect } from "react";

function Header() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobile) {
    return (
      <footer className="fixed bottom-0 left-0 right-0 py-4 px-8 text-white bg-gradient-to-r from-indigo-500 via-blue-500 to-purple-500 z-10">
        <nav className="flex items-center justify-between">
          <Link to="/#projects" className="text-xl font-bold">
            Projects
          </Link>
          <Link to="/about" className="text-xl font-bold">
            About
          </Link>
          <Link to="/#connect" className="text-xl font-bold">
            Connect
          </Link>
        </nav>
      </footer>
    );
  }

  return (
    <header className="hover:animate-pulse flex items-center justify-between py-4 px-8 text-white sticky top-0">
      <Link to="/">
        <img src={logo} alt="personal logo" className="h-8 filter invert" />
      </Link>
      <nav className="flex items-center space-x-4">
        <ul className="flex gap-14">
          <li className="text-xl font-bold">
            <Link to="/#projects">Projects</Link>
          </li>
          <li className="text-xl font-bold">
            <Link to="/about">About</Link>
          </li>
          <li className="text-xl font-bold">
            <Link to="/#connect">Connect</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
