import ProjectCards from "../components/ProjectCards";
import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
import animationData from "../Animation.json"; // Adjust the path accordingly


function Home() {
    const container = useRef(null);

    useEffect(() => {
        const anim = Lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData
        });

        // Cleanup function to remove the animation when the component unmounts
        return () => {
            anim.destroy();
        };
    }, []); // Empty dependency array ensures this effect runs only once

    return (
        <main className="pt-12 mb-12">
            <section className="flex flex-wrap items-center justify-between mb-64">
                {/* Text content */}
                <div className=" text-center max-w-md w-full sm:w-1/2 order-2  sm:order-1">
                    <h1 className="text-gray-50 text-3xl sm:text-7xl font-bold mb-4 sm:mb-0">Genelle Palana</h1>
                    <p className="bg-gradient-to-r from-indigo-500 via-blue-500 to-purple-500 inline-block text-transparent bg-clip-text font-bold text-2xl sm:text-5xl">Front-End Web Developer</p>
                </div>

                {/* Single Lottie animation */}
                <div id="container" ref={container} className="w-full sm:w-1/2 order-1 sm:order-2" alt="animation of astronaut"></div>
            </section>

            <section className="mt-10">
                <h2 id="projects" className="text-xl font-bold text-white text-center sm:text-4xl">Projects</h2>
                <ProjectCards />
            </section>

            <section className="px-6 py-4 md:py-8 bg-gray-900 bg-opacity-80 backdrop-filter 
                                backdrop-blur-md border border-gray-200 rounded-lg text-center mt-6 md:mt-12 
                                mx-auto max-w-md md:max-w-xl">

                <h2 id="connect" className="text-white mb-4 text-xl md:text-2xl font-extrabold">Let's Connect!</h2>

                <p className="text-white mb-4 py-2 md:py-4 px-6 md:px-24">Whether you have questions, exciting projects, or just want to connect,  I'm all ears! Drop me a message anytime—I look forward to hearing from you.</p>

                <a href="mailto:genellepalana@gmail.com" className="inline-block px-4 py-2 bg-purple-600 text-white 
                        font-semibold rounded-lg    hover:bg-purple-700 mb-4 md:mb-12">Email</a>
            </section>
        </main>
    );
}
  
export default Home;