import { HashRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Movie from "./pages/Movie";
import Portfolio from "./pages/Portfolio";
import Verdelux from "./pages/Verdelux";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="bg-neutral-950">
      <Router>
        <Header />
        <Routes>
          <Route index element={<Home />}/>
          <Route path="/home" element={<Home />}/> 
          <Route path="/about" element={<About />}/> 
          <Route path="/movie" element={<Movie />}/> 
          <Route path="/portfolio" element={<Portfolio />}/> 
          <Route path="/verdelux" element={<Verdelux />}/> 
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
