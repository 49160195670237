import React, { useState } from "react";

function FilterToolkit({ onTabChange }) {
  const [activeTab, setActiveTab] = useState("All");

  const tabs = ["All", "Development", "Design"];

  const tools = [
    { name: "Tailwind CSS", category: "Development" },
    { name: "React", category: "Development" },
    { name: "HTML", category: "Development" },
    { name: "CSS", category: "Development" },
    { name: "JavaScript", category: "Development" },
    { name: "Figma", category: "Design" },
    { name: "Adobe XD", category: "Design" },
  ];

  const filteredTools = activeTab === "All" ? tools : tools.filter(tool => tool.category === activeTab);

  function handleTabClick(tab) {
    setActiveTab(tab);
    onTabChange(tab);
  }

  return (
    <div>
      <div className="flex justify-center space-x-4">
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`p-4 py-2 rounded-lg focus:outline-none ${
              activeTab === tab ? "bg-gradient-to-r from-indigo-500 via-blue-500 to-purple-500  text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="text-lg md:text-2xl mt-4 flex flex-wrap justify-center">
        {filteredTools.map((tool, index) => (
          <div key={index} className="mx-2 mb-2 text-white">
            {tool.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterToolkit;
