import React, { useState } from "react";

function MovieDropdown({ title, content }) {
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <article className="w-full max-w-lg mx-auto">
            <section className="py-2 bg-indigo-400 rounded-lg">
                <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex items-center justify-between w-full px-4 py-2 text-lg font-bold text-gray-800 bg-indigo-400 focus:outline-none">
                    <span>{title}</span>
                    {accordionOpen ? <span>▼</span> : <span>▲</span>}
                </button>

                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${accordionOpen ? 'block opacity-100' : 'hidden opacity-0'}`}>
                    {/* Check if content is an image path */}
                    {content.endsWith('.webp') ? (
                        <img src={process.env.PUBLIC_URL + content} alt={title} className="w-full" />
                    ) : (
                        <p className="px-4 py-2 text-lg text-gray-700">{content}</p>
                    )}
                </div>
            </section>
        </article>
    );
}

export default MovieDropdown;
