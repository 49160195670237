import { Link } from "react-router-dom";
import Data from "../data.json";

function ProjectCards() {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-56">
            {Data && Data.map(project => {
                return(
                <div key={project.id} className="bg-black bg-opacity-50 p-4 rounded-lg">
                    <img src={process.env.PUBLIC_URL + project.image} alt="screenshot of moviedatabase website"  className="w-full h-auto rounded-lg hover:animate-bounce-slow"/>
                    
                    <h3 className="text-white text-2xl font-extrabold text-center">{project.title}</h3>
                    <Link className="bg-gradient-to-r from-indigo-500 via-blue-500 to-purple-500 inline-block text-transparent bg-clip-text font-bold text-xl text-center flex justify-center" to={project.link}>View Details ➜</Link>
                </div>
            )})}
        </div>
    );
}
  
export default ProjectCards;