import React from "react";
import fMovies from "../media/Fmovies.webp";
import MovieDropdown from "../components/Movie-Accordion";
import Data from "../data.json";

function Movie() {
    // Find the project with id 1
    const projectId1 = Data.find(project => project.id === 1);

    return (
        <main className="mx-4">
            <article className="flex flex-wrap items-center">
                <section className="w-full md:w-1/2 mb-6 md:mb-0">
                    <img src={fMovies} alt="movie database project on electronic devices" className="w-full md:w-auto" />
                </section>
                <section className="w-full md:w-1/2 px-4">

                    <h1 className="text-white text-xl md:text-4xl font-bold mb-4 text-center">
                        {projectId1.title}
                    </h1>

                    <p className="text-white text-base md:text-2xl mb-4 my-12">{projectId1.details[0].overview}</p>

                    <div className="flex justify-evenly mb-4">
                        <a href="https://genellepalana.com/fmovies/" 
                            className="inline-block px-4 py-2
                             bg-purple-600 text-white font-semibold
                            rounded-lg hover:bg-purple-700">Live Site</a>

                        <a href="https://github.com/genellepalana" 
                            className="inline-block px-4 py-2
                             bg-purple-600 text-white font-semibold 
                             rounded-lg hover:bg-purple-700">GitHub</a>
                    </div>
                </section>
            </article>

            <section className="mt-12 bg-gradient-to-r from-indigo-500 via-blue-500 to-purple-500 rounded-lg mx-auto max-w-4xl md:max-w-3/4 p-8">
                <h2 className="text-purple-900 text-xl md:text-3xl font-bold text-center mb-4">Toolkit</h2>
                <ul className="text-white flex flex-wrap justify-center">
                    {projectId1.toolKit && projectId1.toolKit.map(tool => (
                        <li key={tool} className="mx-2 mb-2 px-4 py-2 bg-indigo-400 text-purple-900 rounded-lg text-xl">
                            {tool}
                        </li>
                    ))}
                </ul>
            </section>

            <section className="mt-16">
                <h2 className="text-white text-xl md:text-3xl font-bold text-center">Project Details</h2>
                <div className="space-y-4 my-12">
                    {/* Check if there is at least 1 element in the details array */}
                    {projectId1.details.length > 0 && (
                        <>
                            {/* Access the first (and only) element in the details array */}
                            <MovieDropdown className="mt-6" title='Highlights' content={projectId1.details[0].highlights} />
                            <MovieDropdown className="mt-6" title='Reflection' content={projectId1.details[0].reflection} />
                        </>
                    )}
                </div>
            </section>
        </main>
    );
}

export default Movie;
