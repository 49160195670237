import React, { useState } from "react";
import FilterToolkit from "../components/FilterToolkit"; 
import selfie from "../media/selfie.webp";

function About() {
    const [activeTab, setActiveTab] = useState("All");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <main className="mx-4">
            
            <section className="flex flex-wrap items-center justify-center mb-8">
                <img className="w-full md:w-2/5 mb-4 md:mb-0 md:mr-8 pt-4" src={selfie} alt="Picture of portfolio owner" />
                <div className="md:text-xl w-full md:w-3/5 flex flex-col justify-center">
                    <h1 className="text-4xl text-white font-bold mb-4 md:mb-8 mt-8">About Me</h1>
                    <p className="text-lg md:text-xl text-white mb-4">
                        Hi! I'm Genelle, a Front-End Web Developer located in Vancouver, BC. With a recent graduation from BCIT's Front-End Web Developer Program, I bring a passion for crafting user-friendly websites.
                    </p>
                    <p className="text-lg md:text-xl text-white mb-4">
                        Prior to venturing into web development, my career spanned diverse roles, including customer service and production assistant in the film industry. The desire to maintain a creative outlet is what led me to embark on the exciting journey of web development!
                    </p>
                </div>
            </section>

            <section className="p-16 md:py-24">
                <h2 className="text-4xl text-center font-bold text-white mb-8">Toolkit</h2>
                <FilterToolkit 
                    onTabChange={handleTabChange} 
                    activeTab={activeTab} 
                    tabClassName="px-6 py-2 rounded-md text-lg font-bold mr-4 mb-4 bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                />
            </section>
        </main>
    );
}
  
export default About;
